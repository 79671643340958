import { setItem, removeItem } from 'utility/localStorageControl';

const actions = {
    LOGIN_BEGIN: 'LOGIN_BEGIN',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_ERR: 'LOGIN_ERR',

    LOGOUT_BEGIN: 'LOGOUT_BEGIN',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    LOGOUT_ERR: 'LOGOUT_ERR',

    MESSAGE_SET: 'MESSAGE_SET',
    MESSAGE_CLEAR: 'MESSAGE_CLEAR',

    loginBegin: (): any => {
        return {
            type: actions.LOGIN_BEGIN,
        };
    },

    loginSuccess: (publicAddress: string, accessToken: string): any => {
        setItem('logged_in', 'true')
        setItem('public_address', publicAddress)
        setItem('access_token', accessToken)
        return {
            type: actions.LOGIN_SUCCESS,
            publicAddress,
            accessToken
        };
    },

    loginErr: (err: string): any => {
        return {
            type: actions.LOGIN_ERR,
            err,
        };
    },

    logoutBegin: (): any => {
        return {
            type: actions.LOGOUT_BEGIN,
        };
    },

    logoutSuccess: (): any => {
        removeItem('logged_in')
        removeItem('public_address')
        removeItem('access_token')
        return {
            type: actions.LOGOUT_SUCCESS,
        };
    },

    logoutErr: (err: string): any => {
        return {
            type: actions.LOGOUT_ERR,
            err,
        };
    },

    messageSet: (message: string): any => {
        return {
            type: actions.MESSAGE_SET,
            message,
        };
    },

    messageClear: (): any => {
        return {
            type: actions.MESSAGE_CLEAR,
        };
    },
};

export default actions;
