import Styled from 'styled-components';

const PwaModalWrapper = Styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    z-index: 9000;
  
    .modal-section {
        position: absolute;
        max-width: 95%;
        bottom: 5%;
        width: fit-content;
        height: fit-content;
        background: #FFFFFF;
        box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.25);
        border-radius: 25.5577px;
        z-index: 9001;
        padding: 15px 20px 15px 12px;
        display: flex;
        flex-direction: column;

        .title-section {
            margin-bottom: 10px;

            .image-container {
                width: 50px;
                height: 50px;
                margin-right: 5px;

                img {
                    width: 100%;
                }
            }

            .title-text {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                color: #000000;
                margin-bottom: 5px;
            }

            .description-text {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 400;
                font-size: 9px;
                line-height: 11px;
                color: #000000;
            }
        }

        .button-section{
            display: flex;
            align-self: center;
            padding-left: 30px;

            .agree-button {
                text-transform: uppercase;
                background: #E40613;
                border: none;
                border-radius: 20px;
                padding: 5px 15px;
                cursor: pointer;

                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 17px;
                text-align: center;
                color: #FFFFFF;
            }

            .cancel-button {
                background: none;
                border: none;
                padding: 5px 25px 5px 45px;
                cursor: auto;
                display: flex;
                align-items: center;
    
                span {
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 17px;
                    text-align: center;
                    color: #343434;
    
                    border-bottom: 1px solid #343434;
                    cursor: pointer;
                }
            }
        }
    }

    @media only screen and (min-width: 768px) {
        .modal-section {
            padding: 18px 70px 23px 20px;
    
            .title-section {
                margin-bottom: 30px;

                .image-container {
                    width: 100px;
                    height: 100px;
                    margin-right: 10px;
                }

                .title-text {
                    font-size: 30px;
                    line-height: 37px;
                    margin-bottom: 10px;
                }

                .description-text {
                    font-size: 18px;
                    line-height: 22px;
                }
            }

            .button-section{
                padding-left: 0px;

                .agree-button {
                    padding: 10px 35px;
                    cursor: pointer;
    
                    font-size: 24px;
                    line-height: 29px;
                    margin-right: 30px;
                }

                .cancel-button {
                    padding: 5px 25px 5px 70px;
        
                    span {
                        font-size: 24px;
                        line-height: 29px;
                    }
                }
            }
        }
    }
`

export { PwaModalWrapper };