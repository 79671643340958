import axios, { AxiosPromise } from 'axios';
import store from 'redux/store';
import { getItem } from 'utility/localStorageControl';
import { default as authActions } from 'redux/auth/actions';
import { createBrowserHistory } from 'history';
import commonActions from 'redux/common/actions';

const history = createBrowserHistory();

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const client = axios.create({
    baseURL: API_ENDPOINT,
    headers: {
        'Content-Type': 'application/json',
        'X-API-VERSION': 2
    },
});

class APIService {
    static get(path = '', params = {}, optionalHeader = {}): AxiosPromise {
        return client({
            method: 'GET',
            url: path,
            params,
            headers: { ...optionalHeader },
        });
    }

    static post(path = '', data = {}, optionalHeader = {}): AxiosPromise {
        return client({
            method: 'POST',
            url: path,
            data,
            headers: { ...optionalHeader },
        });
    }

    static patch(path = '', data = {}): AxiosPromise {
        return client({
            method: 'PATCH',
            url: path,
            data: JSON.stringify(data),
        });
    }

    static put(path = '', data = {}): AxiosPromise {
        return client({
            method: 'PUT',
            url: path,
            data: JSON.stringify(data),
        });
    }

    static download(path = ''): AxiosPromise {
        return client({
            method: 'GET',
            url: path,
            responseType: 'blob', // important
        });
    }
}



const { dispatch } = store;

/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */
client.interceptors.request.use((config) => {
    // do something before executing the request
    // For example tag along the bearer access token to request header or set a cookie
    const requestConfig = config;
    const { headers } = config;

    const accessToken = getItem('access_token');
    if (accessToken) {
        requestConfig.headers = { ...headers, Authorization: `${getItem('access_token')}` };
    }

    return requestConfig;
});

client.interceptors.response.use(
    (response) => response,
    (error) => {
        /**
         * Do something in case the response returns an error code [3**, 4**, 5**] etc
         * For example, on token expiration retrieve a new access token, retry a failed request etc
         */
        const { response } = error;
        
        if (error.toJSON().message === 'Network Error' && history.location.pathname != '/offline') {
            dispatch(commonActions.goOffline());
        }

        // const originalRequest = error.config;
        if (response) {
            // Handle access token expired, logout user and show message
            if (response.status === 401 && response.data.errors === 'E_UNAUTHORIZED_ACCESS: Unauthorized access') {
                dispatch(authActions.messageSet('Session expired! Please login again.'));
                dispatch(authActions.logoutSuccess());
            }

            if (response.status === 500) {
                // do something here
            } else {
                // return originalRequest;
            }
        }
        return Promise.reject(error);
    },
);
export default APIService;
