const commonActions = {
    SET_TOTAL_ASSETS_COUNT: 'SET_TOTAL_ASSETS_COUNT',
    ADD_LOADED_ASSETS_COUNT: 'ADD_LOADED_ASSETS_COUNT',
    RESET_LOADED_ASSETS_COUNT: 'RESET_LOADED_ASSETS_COUNT',
    GO_OFFLINE: 'GO_OFFLINE',

    setTotalAssetsCount: (count: number): any => {
        return {
            type: commonActions.SET_TOTAL_ASSETS_COUNT,
            payload: {
                count,
            },
        };
    },

    addLoadedAssetsCount: (): any => {
        return {
            type: commonActions.ADD_LOADED_ASSETS_COUNT,
        };
    },

    resetLoadedAssetsCount: (): any => {
        return {
            type: commonActions.RESET_LOADED_ASSETS_COUNT,
        };
    },

    goOffline: (): any => {
        return {
            type: commonActions.GO_OFFLINE,
        };
    },
};

export default commonActions;
