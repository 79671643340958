import Styled from 'styled-components';

const LoadingWrapper = Styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
`;

const PageWrapper = Styled.div`
    position: relative;
    min-height: 100vh;
`;

const ContentBodyWrapper = Styled.div`
    position: relative;
    padding-top: 108px;

    @media only screen and (min-width: 768px) {
        padding-top: 150px;
    }

    @media only screen and (min-width: 1200px) {
        padding-top: 120px;
    }
`;

export { LoadingWrapper, PageWrapper, ContentBodyWrapper };
