import React from 'react';
import { FooterContainer } from './style';

const Footer = (): JSX.Element => {
    const year = new Date().getFullYear();

    return (
        <FooterContainer className="d-flex justify-content-center align-items-center" id="footer">
            <label className="copyright">© {year} by LIQUI MOLY Asia Pacific</label>
        </FooterContainer>
    );
};

export default Footer;
