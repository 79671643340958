import React, { useState, useEffect } from 'react';
import { PwaModalWrapper } from './style';
import useAddToHomescreenPrompt from 'hooks/useAddToHomeScreenPrompt';
import useShouldShowPrompt from 'hooks/useShouldShowPrompt';

// Assets
import LiquimolyLogo from 'assets/img/liquimoly_logo_black.png';

const PwaModal = (): JSX.Element => {
    const [webPrompt, promptToInstall] = useAddToHomescreenPrompt();
    const [isVisible, setVisibleState] = useState(false);
    const [isCompatible, setIsCompatible] = useState(false);
    const [userShouldBePromptedToInstall, handleUserSeenInstallPrompt] = useShouldShowPrompt('webInstallPromptedAt');

    const hide = () => {
        setVisibleState(false);
        handleUserSeenInstallPrompt();
    };

    const handleAgreeInstall = () => {
        promptToInstall();
        hide();
    };

    useEffect(() => {
        const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
        if (isStandalone) {
            return;
        }

        // If pwa not installed, check if browser is compatible
        if (webPrompt) {
            setIsCompatible(true);
        } else {
            setIsCompatible(false);
        }

        if (userShouldBePromptedToInstall) {
            setVisibleState(true);
        } else {
            setVisibleState(false);
        }
    }, [webPrompt]);

    if (!isVisible) {
        return <></>;
    }

    return (
        <PwaModalWrapper>
            {!isCompatible && isVisible ? (
                <div className="modal-section">
                    <div className="title-section d-flex align-items-end">
                        <div className="image-container">
                            <img src={LiquimolyLogo} alt="logo-image" />
                        </div>
                        <div className="d-flex flex-column justify-content-center pb-1 pb-md-2">
                            <div className="title-text">LIQUI MOLY Service Partner</div>
                            <div className="description-text">
                                You may add this app to HomeScreen through browser setting menu
                            </div>
                        </div>
                    </div>
                    <div className="button-section d-flex justify-content-center p-0">
                        <button className="agree-button ms-0 ms-md-5" onClick={hide}>
                            Okay
                        </button>
                    </div>
                </div>
            ) : (
                <div className="modal-section">
                    <div className="title-section d-flex align-items-end">
                        <div className="image-container">
                            <img src={LiquimolyLogo} alt="logo-image" />
                        </div>
                        <div className="d-flex flex-column justify-content-center pb-1 pb-md-2">
                            <div className="title-text">LIQUI MOLY Service Partner</div>
                            <div className="description-text">
                                Install this website as a web app on your Apps screen?
                            </div>
                        </div>
                    </div>
                    <div className="button-section">
                        <button className="agree-button" onClick={handleAgreeInstall}>
                            Agree
                        </button>
                        <button className="cancel-button">
                            <span onClick={hide}>Cancel</span>
                        </button>
                    </div>
                </div>
            )}
        </PwaModalWrapper>
    );
};

export default PwaModal;
