import React, { ReactNode } from 'react';
import { PageWrapper, ContentBodyWrapper } from 'styles/style';
import NavBar from 'containers/NavBar';
import Footer from 'components/Footer';
import PwaModel from 'components/PwaModal';

interface IProps {
    children: ReactNode;
}

const PageLayout = ({ children }: IProps): JSX.Element => {
    return (
        <PageWrapper>
            <PwaModel />
            <NavBar />
            <ContentBodyWrapper>{children}</ContentBodyWrapper>
            <Footer />
        </PageWrapper>
    );
};

export default PageLayout;
