/**
 * It's suggested to configure the RESTful endpoints in this file
 * so that there is only one source of truth, future update of endpoints
 * could be done from here without refactoring on multiple places throughout the app
 */
const API = {
    general: {
        getWorkshopTypes: '/Api/workshop-types',
        getDistances: '/Api/distances',
        getProducts: '/Api/products',
    },
    workshop: {
        getWorkshops: '/Api/workshops',
        getPoints: '/Api/workshop-point',
    },
    workshopDetail: {
        getWorkshopDetail: '/Api/workshops/{id}',
        getWorkshopImages: '/Api/workshops/{id}/images',
        getWorkshopProduct: '/Api/workshops/{id}/products',
    },
    productCategories: {
        getProductCategories: '/Api/workshops/{id}/product-categories',
    },
    appointment: {
        postAppointment: '/Api/workshops/{id}/appointment'
    },
};

export { API };
