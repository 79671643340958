import Geocode from 'react-geocode';

type GeocodeResult = {
    country: string;
    state: string;
    city: string;
};

export const getLocationByCoords = async (latitude: number, longitude: number): Promise<GeocodeResult | null> => {
    // Get formatted address, city, state, country from latitude & longitude when
    // Geocode.setLocationType("ROOFTOP") enabled
    // the below parser will work for most of the countries
    const response = await Geocode.fromLatLng(latitude, longitude);
    if (!response) {
        return null;
    }

    let city, state, country;
    for (let i = 0; i < response.results[0].address_components.length; i++) {
        for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
            switch (response.results[0].address_components[i].types[j]) {
                case 'locality':
                    city = response.results[0].address_components[i].long_name;
                    break;
                case 'administrative_area_level_1':
                    state = response.results[0].address_components[i].long_name;
                    break;
                case 'country':
                    country = response.results[0].address_components[i].short_name;
                    break;
            }
        }
    }

    // Cache in session storage
    sessionStorage.setItem('location', JSON.stringify({ city, state, country }));

    return {
        country,
        state,
        city,
    };
};
