import LoadingLogo from 'assets/img/loading.png';

import ArrowDown from 'assets/img/home/arrow_down.svg';
import GridViewIconNormal from 'assets/img/home/grid_view_icon.svg';
import GridViewIconRed from 'assets/img/home/grid_view_icon_red.svg';
import ListViewIconNormal from 'assets/img/home/list_view_icon.svg';
import ListViewIconRed from 'assets/img/home/list_view_icon_red.svg';
import Checkbox from 'assets/img/home/checkbox.svg';
import CheckboxChecked from 'assets/img/home/checkbox_checked.svg';

import chevronLeft from 'assets/img/chevron-left.svg';

import mail from 'assets/img/detailSection/mail.svg';
import contact from 'assets/img/detailSection/contact.svg';
import direction from 'assets/img/detailSection/direction.svg';
import paginationLeft from 'assets/img/detailSection/pagination_left.svg';
import product1 from 'assets/img/detailSection/product_1.svg';
import imageAlbum from 'assets/img/image_album.svg';
import diagnostic from 'assets/img/detailSection/diagnostic.svg';
import maintenance from 'assets/img/detailSection/maintenance.svg';
import servicing from 'assets/img/detailSection/servicing.svg';
import battery_replacement from 'assets/img/detailSection/battery_replacement.svg';
import tyre_change from 'assets/img/detailSection/tyre_change.svg';
import car_wash from 'assets/img/detailSection/car_wash.svg';
import repair from 'assets/img/detailSection/repair.svg';
import accessories from 'assets/img/detailSection/accessories.svg';
import clockOpen from 'assets/img/detailSection/clock_open.svg';
import clockClose from 'assets/img/detailSection/clock_close.svg';
import chevron from 'assets/img/detailSection/chevron_up.svg';

import locate from 'assets/img/locate.svg';
import phone from 'assets/img/phone.svg';
import clock from 'assets/img/clock.svg';

interface SETTINGS_TYPE {
    defaultMapCenter: {
        lat: number;
        lng: number;
    };
}

const SETTINGS: SETTINGS_TYPE = {
    defaultMapCenter: {
        lat: 3.0838862,
        lng: 101.581654,
    },
};

const PRELOAD_IMAGES: string[] = [
    // Loading screen
    LoadingLogo,

    // Home page
    ArrowDown,
    GridViewIconNormal,
    GridViewIconRed,
    ListViewIconNormal,
    ListViewIconRed,
    Checkbox,
    CheckboxChecked,

    // Appointment Page
    chevronLeft,

    // Detail page
    mail,
    contact,
    direction,
    paginationLeft,
    product1,
    imageAlbum,
    diagnostic,
    maintenance,
    servicing,
    battery_replacement,
    tyre_change,
    car_wash,
    repair,
    accessories,
    clockOpen,
    clockClose,
    chevron,

    // Workshop card
    locate,
    phone,
    clock,
]

export { SETTINGS, PRELOAD_IMAGES };
