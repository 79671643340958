import React from 'react';
import LoadingLogo from 'assets/img/loading.png';
import { Link } from 'react-router-dom';

const OfflinePage = (): JSX.Element => {
    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                background: '#00519E',
                backgroundSize: 'cover',
                zIndex: 999,
                overflow: 'hidden',
            }}
        >
            <img
                className="app-logo px-0"
                style={{ width: 230, height: 'auto', margin: '0 auto' }}
                src={LoadingLogo}
                alt="LIQUI MOLY"
            />
            <div style={{ fontFamily: 'Montserrat', color: 'white', fontSize: 16, textAlign: 'center' }}>
                No internet connection, please try again.
            </div>
            <Link to="/">
                <button
                    className="my-2 px-3"
                    style={{
                        backgroundColor: 'red',
                        border: 'unset',
                        borderRadius: 25,
                        color: 'white',
                        fontFamily: 'Montserrat',
                        height: 50,
                    }}
                >
                    Back to home page
                </button>
            </Link>
        </div>
    );
};

export default OfflinePage;
