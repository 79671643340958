import Styled from 'styled-components';

const FooterContainer = Styled.footer`
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: fit-content;
    height: 55px;
    background: transparent;

    .copyright{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 15px;
        color: #696969;
    }

    @media only screen and (min-width: 768px) {
        height: 75px;

        .copyright{
            font-size: 16px;
            line-height: 20px;
        }

    }
`;

export { FooterContainer };
