import React, { Suspense, useEffect, useState } from 'react';
import { Switch, Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import commonActions from 'redux/common/actions';
import { PRELOAD_IMAGES } from 'config/settings';
import lazyWithRetry from 'components/lazyWithRetry';

// Geocode
import Geocode from 'react-geocode';
import PageLayout from 'containers/PageLayout';
Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API_KEY);

const HomePage = lazyWithRetry(() => import('pages/HomePage'));
const WorkshopPage = lazyWithRetry(() => import('pages/WorkshopPage'));
const WorkshopDetailPage = lazyWithRetry(() => import('pages/WorkshopDetailPage'));
const WorkshopImagesPage = lazyWithRetry(() => import('pages/WorkshopImagesPage'));
const WorkshopAppointmentPage = lazyWithRetry(() => import('pages/WorkshopAppointmentPage'));
import OfflinePage from 'offline';
import { RootState } from 'redux/rootReducers';

const NotFound = () => {
    return <Redirect to="/" />;
};

const Routes = (): JSX.Element => {
    const { pathname } = useLocation();
    const history = useHistory();

    const dispatch = useDispatch();
    const setTotalAssetsCount = (total: number) => dispatch(commonActions.setTotalAssetsCount(total));
    const addLoadedAssetsCount = () => dispatch(commonActions.addLoadedAssetsCount());

    const offlineCount = useSelector(({ common }: RootState) => common.offline)

    // Preload images
    useEffect(() => {
        setTotalAssetsCount(PRELOAD_IMAGES.length);

        PRELOAD_IMAGES.forEach((preloadImage) => {
            const image = new Image();
            image.src = preloadImage;
            image.onload = function () {
                addLoadedAssetsCount();
            };
            image.onerror = function () {
                addLoadedAssetsCount();
            };
        });
    }, []);

    // Scroll to top when change path
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        if (offlineCount) {
            history.push('/offline');
        }
    }, [offlineCount]);

    return (
        <Suspense fallback={<PageLayout><></></PageLayout>}>
            <Switch>
                <Route exact path="/" component={HomePage} />
                <Route exact path="/offline" component={OfflinePage} />
                <Route exact path="/workshop" component={WorkshopPage} />
                <Route exact path="/workshop/:id" component={WorkshopDetailPage} />
                <Route exact path="/workshop/:id/images" component={WorkshopImagesPage} />
                <Route exact path="/workshop/:id/appointment" component={WorkshopAppointmentPage} />

                {/* Route Not Found */}
                <Route exact path="*" component={NotFound} />
            </Switch>
        </Suspense>
    );

};

export default Routes;
