import { combineReducers } from 'redux';
import CommonReducer from './common/reducer';
import AuthReducer from './auth/reducer';

const rootReducers = combineReducers({
    common: CommonReducer,
    auth: AuthReducer
});

export type RootState = ReturnType<typeof rootReducers>

export default rootReducers;
