import actions from './actions';
import { getItem } from 'utility/localStorageControl';

const { LOGIN_BEGIN, LOGIN_SUCCESS, LOGIN_ERR, LOGOUT_BEGIN, LOGOUT_SUCCESS, LOGOUT_ERR, MESSAGE_SET, MESSAGE_CLEAR } =
    actions;

const initState = {
    login: getItem('logged_in') ? true : false,
    publicAddress: getItem('public_address') ?? '',
    accessToken: getItem('access_token') ?? '',
    loading: false,
    error: null,
    message: '',
};

/**
 *
 * @todo impure state mutation/explaination
 */
const AuthReducer = (state = initState, action: { type: string, publicAddress: string, accessToken: string, err: string, message: string }): any => {
    const { type, publicAddress, accessToken, err, message } = action;
    switch (type) {
        case LOGIN_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                login: true,
                publicAddress: publicAddress,
                accessToken: accessToken,
                loading: false,
                error: null,
            };
        case LOGIN_ERR:
            return {
                ...state,
                error: err,
                loading: false,
            };
        case LOGOUT_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case LOGOUT_SUCCESS:
            return {
                ...state,
                login: false,
                publicAddress: '',
                accessToken: '',
                loading: false,
            };
        case LOGOUT_ERR:
            return {
                ...state,
                error: err,
                loading: false,
            };
        case MESSAGE_SET:
            return {
                ...state,
                message,
            };
        case MESSAGE_CLEAR:
            return {
                ...state,
                message: '',
            };
        default:
            return state;
    }
};
export default AuthReducer;
