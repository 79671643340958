import Styled from 'styled-components';

const Nav = Styled.div`
    position: fixed;
    width: 100%;
    top: 0px;
    padding: 4px 15px;
    background-color: #00519E;
    z-index: 999;

    -webkit-transition: height 200ms ease-in-out;
    -moz-transition: height 200ms ease-in-out;
    transition: height 200ms ease-in-out;

    .logo-container {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        margin-bottom: 10px;

        img {
            width: 50px;
            height: 100%;
        }
    }

    .navbar-section {
        display: flex;
        flex-wrap: wrap;

        .searchbar-container {
            margin: 0 10px 0 0;
            width: 50vw;
            flex-grow: 1;

            .searchbar {
                height: 34px;
                background: #FFFFFF;
                border: 0.82px solid #A9A9A9;
                box-shadow: 0px 1.64px 8.2px rgba(0, 0, 0, 0.25);
                border-radius: 49.2px;
                justify-content: start;
                align-items: center;
                cursor: pointer;

                padding: 0 10px;

                .mobile-search {
                    width: 13px;
                    height: 13px;
                }

                span {
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;

                    color: #343434;
                    margin: 0 7px;
                    white-space: nowrap;
                    overflow-x: auto;

                    -ms-overflow-style: none;
                    scrollbar-width: none;
                }

                span::-webkit-scrollbar {
                    display: none;
                }
            }

            .vehicle-menu {
                justify-content: center;
                align-items: center;
                height: 45px;
                margin-bottom: 10px;

                .skeleton-container {
                    width: fit-content;
                    display: flex;

                    .react-loading-skeleton {
                        margin: 0 20px;
                    }
                }

                .vehicle-container {
                    position: relative;
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 22px;
                    text-align: center;
                    cursor: pointer;
                    color: #FFFFFF;
                    margin: 0 1.5rem;
                }

                .vehicle-container.active:after {
                    content: "";
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%);
                    bottom: -7px;
                    border-top: 2px solid #FFFFFF;
                    width: 100%;
                }
            }
        }

        .searchmenu-container {
            position: absolute;
            bottom: 10px;
            left: 45%;
            transform: translateX(-45%);

            .searchmenu {
                position: relative;
                width: 800px;
                height: 70px;
                background: #EEEEEE;
                border: 1px solid #A9A9A9;
                border-radius: 60px;
                justify-content: space-between;
                align-items: center;
                animation: searchmenu-animation 0.2s linear !important;
                padding-right: 20px;

                .option-bar {
                    height: 100%;
                    display: flex;
                    align-items: center;

                    .vertical-border {
                        height: 34px;
                        width: 0px;
                        border-left: 2px solid #A9A9A9;
                        
                        margin: 0 1rem;
                    }

                    .first-item {
                        margin: 0 !important; 
                        padding: 0 1.75rem 0 2rem !important; 
                    }

                    .option-item {
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        width: 199px;
                        height: 70px;
                        padding: 0 1.75rem;
                        cursor: pointer;

                        .option-title {
                            font-family: 'Montserrat';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 17px;
                            color: #343434;
                        }

                        .option-textfield {
                            font-family: 'Montserrat';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 17px;
                            color: #343434;

                            background: transparent;
                            border: none;
                            padding: 0;

                            ::placeholder {
                                font-weight: 300
                            }

                            :focus {
                                outline: 0;
                                outline-color: transparent;
                                outline-style: none;
                            }
                        }

                        .close-button {
                            position: absolute;
                            right: 15px;
                            top: 50%;
                            transform: translateY(-50%);
                            justify-content: center;
                            align-items: center;
                            width: 20px;
                            height: 20px;
                            cursor: pointer;
                            z-index: 99;

                            img {
                                width: 100%;
                            }
                        }
                    }

                    .active {
                        background: #FFFFFF !important;
                        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25) !important;
                        border-radius: 60px !important;
                    }
                }

                .search-button {
                    width: 35px;
                    height: 35px;
                    background: #E40613;
                    border-radius: 50%;

                    border: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    -webkit-transition: width 0.25s ease-in-out;
                    -moz-transition: width 0.25s ease-in-out;
                    transition: width 0.25s ease-in-out; 

                    img {
                        width: 15px;
                        height: 15px;
                    }
                }
            }
        }
    }
    
    .isMap {
        width: 78px !important;
        padding: 0 5px !important;
    }

    .toggle-container {
        width: 80px;
        display: flex;
        justify-content: flex-end;

        a {
            text-decoration: none;
        }

        .toggle-button {
            width: 71px;
            height: 34px;
            background: #FFFFFF;
            border: 0.694118px solid #A7A7A7;
            border-radius: 20.8235px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            padding: 0 7px;

            span {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;

                color: #343434;
                padding-left: 5px;
            }

            .globe-image {
                width: 17px;
                height: 17px;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 14px;
                    height: 14px;
                }
            }
        }
    }
                    
    .cursor-pointer {
        cursor: pointer !important;
    }

    .error-container {
        height: 81px;
        width: 100%;
        padding: 30px 0;
        margin: 0 auto;
        background: #FFFFFF;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
        border-radius: 15px;
    }

    .nofound-text {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: #343434;
    }

    .product-list {
        max-height: 280px;
        padding: 8px;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        align-items: start;

        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .workshop-list, .address-list {
        max-height: 280px;
        padding: 8px;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .product-list::-webkit-scrollbar, .workshop-list::-webkit-scrollbar, .address-list::-webkit-scrollbar {
        display: none;
    }

    .mobile-searchmenu {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        background: #FFFFFF;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
        padding: 4px 15px 24px ;

        .searchmenu-content {
            width: 100%;
            margin: 0 auto;

            .vehicle-options {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                height: 34px;
                margin: 0 15px 10px 13px;
                overflow-x: auto;

                -ms-overflow-style: none;
                scrollbar-width: none;

                .skeleton-container {
                    width: fit-content;
                    display: flex;

                    .react-loading-skeleton {
                        margin-right: 30px;
                    }
                }
    
                .vehicle-container {
                    position: relative;
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                    text-align: center;
                    cursor: pointer;
                    color: #343434;
                    white-space: nowrap;
                    margin-right: 30px;
                }
    
                .vehicle-container.active:after {
                    content: "";
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%);
                    bottom: -7px;
                    border-top: 2px solid #343434;
                    width: 100%;
                }
            }
            
            .vehicle-options::-webkit-scrollbar {
                display: none;
            }
    
            .back-button {
                width: 10px;
                height: 15px;
    
                img {
                    width: 100%;
                }
            }
    
            .section-title {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                color: #343434;
            }
    
            .input-field {
                width: 100%;
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                line-height: 17px;
                color: #343434;
                margin-bottom: 15px;
                background: transparent;
                border: none;
                padding: 0;
                padding-right: 10px;
    
                :focus {
                    outline: 0;
                    outline-color: transparent;
                    outline-style: none;
                }
            }
    
            .close-button {
                justify-content: center;
                align-items: center;
                width: 20px;
                height: 20px;
                cursor: pointer;
    
                img {
                    width: 100%;
                }
            }
    
            .product-section {
                margin: 10px;
                padding-left: 5px;
    
                .product-item {
                    align-items: center;
                    padding: 10px;
                    margin-left: 0;
                    margin-bottom: 25px;
                    width: 100%;            
                    height: 104px;
                    background: #FFFFFF;
                    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
                    border-radius: 15px;
                    cursor: pointer !important;
            
                    .image-container {
                        max-width: 66px;
                        min-width: 66px;
                        height: 70px;
                        border: 0.5px solid #E40613;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                        border-radius: 8.75px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-grow: 1;
                        margin: 0 auto;
            
                        img {
                            width: auto;
                            height: auto;
                            max-width: 63px;
                            max-height: 63px;
                        }
                    }
            
                    .details-container {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        height: 70px;
            
                        .detail-name {
                            width: 100%;
                            font-family: 'Montserrat';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 14px;
                            color: #343434;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                        }
            
                        .detail-descriptions {
                            .detail-text {
                                width: 100%;
                                font-family: 'Montserrat';
                                font-style: normal;
                                font-weight: 700;
                                font-size: 12px;
                                line-height: 14px;
                                color: #343434;
                                overflow: hidden; 
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }
                        }
                    }
                }
            }
    
            .workshop-section, .address-section {
                margin: 10px;
                padding-left: 5px;
    
                .workshop-item {
                    padding: 10px 15px;
                    margin-left: 0;
                    width: 100%;
                    background: #FFFFFF;
                    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
                    border-radius: 15px;
                    margin-bottom: 15px;
        
                    .location-container {
                        .location-image {
                            width: 30px;
                            height: 30px;
                            background: #EEEEEE;
                            border-radius: 10px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer !important;    
        
                            img {
                                width: 45%;
                            }
                        }
                    }
        
                    .workshop-container {
                        .workshop-name {
                            font-family: 'Montserrat';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 17px;
        
                            color: #343434;
                            margin-bottom: 3px;
                        }
        
                        .workshop-address {
                            font-family: 'Montserrat';
                            font-style: normal;
                            font-weight: 200;
                            font-size: 10px;
                            line-height: 12px;
        
                            color: #343434;
                        }
                    }
                }
            }

            .search-button {
                width: 95px;
                height: 28px;
                background: #E40613;
                border-radius: 20px;

                border: none;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: auto;
                margin-top: 20px;

                img {
                    width: 13px;
                    height: 13px;
                }

                .search-text {
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                    text-align: center;
                    margin-left: 6px;
                    color: #FFFFFF;
                }
            }
        }
    }

    .loading-container {
        width: 100%;
        justify-content: center;
    }

    &.scrolling-down {
        transform: translate3d(0, -100%, 0);
    }

    &.scrolling-up {
        transform: none;
    }

    @keyframes searchmenu-animation {
        0% {
            width: 0;
            height: 0px;
        }

        100% {
            width: 800px;
            height: 70px;
        }
    }

    @media screen and (max-width: 400px) {
        .workshop-section, .product-section {
            padding-left: 14px !important;
        }
    }

    @media screen and (min-width: 400px) {
        padding: 4px 21px;

        .mobile-searchmenu {
            padding: 4px 24px 24px;
    
            .searchmenu-content {
                width: 350px;

                .vehicle-options {
                    margin: 0 15px 10px 29px;
                }

                .address-section {
                    margin: 5px 29px 10px 15px;
                }

                .product-section {
                    margin: 10px 29px;
                    padding-left: 0;
        
                    .product-item {
                        width: 275px;
                        padding: 16px;

                        .details-container {
                            .detail-name {
                                width: 100%;
                                font-size: 14px;
                                line-height: 17px;
                            }
                
                            .detail-descriptions {
                                .detail-text {
                                    font-size: 14px;
                                    line-height: 17px;
                                }
                            }
                        }
                    }
                    
                }
                
                .workshop-section {
                    margin: 10px 29px;
                    padding-left: 0;    
                }
            }
        }
    }
    
    @media screen and (min-width: 768px) {
        padding: 24px 32px 0;

        .logo-container {
            width: 100px;
            height: 100px;

            img {
                width: 100% !important;
            }
        }

        .navbar-section {
            .searchbar-container {
                width: auto;
                margin: 30px auto 0;

                .searchbar {
                    width: 370px;
                    height: 50px;
                    padding: 0 7px;
                    margin: 0 auto;
                    justify-content: space-between;
                    border: 1px solid #A9A9A9;
                    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
                    border-radius: 60px;

                    span {
                        font-size: 16px;
                        line-height: 20px;
                        padding-left: 20px;
                    }

                    .search-image {
                        width: 35px;
                        height: 35px;
                        background: #E40613;
                        border-radius: 50%;
    
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: none;
    
                        img {
                            width: 15px;
                            height: 15px;
                        }
                    }
                }

                .vehicle-menu { 
                    margin-top: 12px;
                }
            }

            .searchmenu-container {
                .searchmenu {
                    width: 708px;
                    height: 70px;
                    padding-right: 15px;

                    .option-bar {
                        .vertical-border {
                            margin: 0 0.75rem;
                        }
    
                        .first-item {
                            padding: 0 1.75rem 0 2rem !important; 
                        }
    
                        .option-item {
                            padding: 0 1.75rem;
    
                            .option-textfield {
                                padding-right: 10px;
                            }

                            .close-button {
                                right: 15px;
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }

                    .search-button {
                        width: 35px;
                        height: 35px;
                        background: #E40613;
                        border-radius: 50%;
    
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        -webkit-transition: width 0.25s ease-in-out;
                        -moz-transition: width 0.25s ease-in-out;
                        transition: width 0.25s ease-in-out; 
    
                        img {
                            width: 15px;
                            height: 15px;
                        }
                    }
                }
            }
        }

        .isMap {
            width: 104px !important;
            padding: 0 12px !important;
        }

        .toggle-container {
            width: 150px;
            margin: 30px 0 0;

            .toggle-button {
                width: 140px;
                height: 50px;
                padding: 0 12px;
                border: 1px solid #A7A7A7;
                border-radius: 30px;

                span {
                    font-size: 16px;
                    line-height: 20px;
                    padding-left: 2px;
                }

                .globe-image {
                    width: 20px;
                    height: 20px;

                    img {
                        width: 17px;
                        height: 17px;
                    }
                }
            }
        }

        .address-modal {
            position: absolute;
            left: 0;
            top: 115%;
            width: 283px;
            padding: 15px 35px;
            background: #FFFFFF;
            box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
            border-radius: 15px;
            cursor: auto;
    
            .workshop-item {
                width: 100%;
                margin-bottom: 15px;

                .location-container {
                    .location-image {
                        width: 30px;
                        height: 30px;
                        background: #EEEEEE;
                        border-radius: 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;

                        img {
                            width: 45%;
                        }
                    }
                }

                .workshop-container {
                    .workshop-name {
                        font-family: 'Montserrat';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 17px;

                        color: #343434;
                        margin-bottom: 3px;
                    }

                    .workshop-address {
                        font-family: 'Montserrat';
                        font-style: normal;
                        font-weight: 200;
                        font-size: 10px;
                        line-height: 12px;

                        color: #343434;
                    }
                }
            }
         }
    
        .product-modal {
            position: absolute;
            left: 59%;
            top: 115%;  
            transform: translateX(-59%);
            width: 323px;
            padding: 17px 27px;
            background: #FFFFFF;
            box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
            border-radius: 15px;
            cursor: auto;
    
            .product-item {
                width: 100%;
                margin-bottom: 25px;
                cursor: pointer;
        
                .image-container {
                    width: 100%;
                    height: 70px;
                    border: 0.5px solid #E40613;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    border-radius: 8.75px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
        
                    img {
                        width: auto;
                        height: auto;
                        max-width: 63px;
                        max-height: 63px;
                    }
                }
        
                .details-container {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
        
                    .detail-name {
                        font-family: 'Montserrat';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 17px;
                        color: #343434;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                    }
        
                    .detail-descriptions {
                        .detail-text {
                            font-family: 'Montserrat';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 14px;
                            line-height: 17px;
                            color: #343434;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
        }
    
        .workshop-modal {
            position: absolute;
            top: 115%; 
            left: 105%;
            transform: translateX(-105%);
            width: 283px;
            padding: 15px 35px;
            background: #FFFFFF;
            box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
            border-radius: 15px;
            cursor: auto;
    
            .workshop-item {
                width: 100%;
                margin-bottom: 15px;

                .location-container {
                    .location-image {
                        width: 30px;
                        height: 30px;
                        background: #EEEEEE;
                        border-radius: 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
    
                        img {
                            width: 45%;
                        }
                    }
                }
    
                .workshop-container {
                    .workshop-name {
                        font-family: 'Montserrat';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 17px;
    
                        color: #343434;
                        margin-bottom: 3px;
                    }
    
                    .workshop-address {
                        font-family: 'Montserrat';
                        font-style: normal;
                        font-weight: 200;
                        font-size: 10px;
                        line-height: 12px;
    
                        color: #343434;
                    }
                }
            }
        }

        .workshop-list, .address-list {
            padding: 0px;
        }   

        @keyframes searchmenu-animation {
            0% {
                width: 0;
                height: 0px;
            }
    
            100% {
                width: 708px;
                height: 70px;
            }
        }
    }

    @media screen and (min-width: 1200px) {
        padding: 10px 50px 0;

        .navbar-section {
            .searchbar-container {
                margin: 24px auto 0;

                .searchbar {
                    width: 400px;

                    span {
                        font-size: 18px;
                        line-height: 22px;
                    }
                }

                .vehicle-menu { 
                    margin-top: -20px;
                }
            }

            .searchmenu-container {
                .searchmenu {
                    width: 800px;
                    padding-right: 20px;
    
                    .option-bar {
                        .vertical-border {
                            margin: 0 1rem;
                        }

                        .first-item {
                            padding: 0 1.75rem 0 2rem !important; 
                        }
    
                        .option-item {
                            padding: 0 1.75rem;
    
                            .close-button {
                                right: 15px;
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                    
                    .search-button {
                        .search-text {
                            font-family: 'Montserrat';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 18px;
                            line-height: 22px;
                            text-align: center;
                            
                            color: #FFFFFF;
                        }
                    }
    
                    .selected {
                        width: 106px !important;
                        border-radius: 20px !important;
                        justify-content: space-between;
                        padding: 0 10px;
    
                        -webkit-transition: width 0.25s ease-in-out;
                        -moz-transition: width 0.25s ease-in-out;
                        transition: width 0.25s ease-in-out; 
                    }
                }
            }
        }

        .isMap {
            width: 150px !important;
            padding: 0 20px !important;
        }

        .toggle-container {
            width: 190px;
            margin: 24px 0 0;

            .toggle-button {
                width: 170px;
                
                span {
                    font-size: 18px;
                    line-height: 22px;
                    padding-left: 5px;
                }

                .globe-image {
                    width: 30px;
                    height: 30px;

                    img {
                        width: 25px;
                        height: 25px;
                    }
                }
            }
        }

        .product-modal {
            left: 50%;
            transform: translateX(-50%);
        }

        .workshop-modal { 
            left: 90%;
            transform: translateX(-90%);
        }

        @keyframes searchmenu-animation {
            0% {
                width: 0;
                height: 0px;
            }
    
            100% {
                width: 800px;
                height: 70px;
            }
        }
    }

    @media screen and (min-width: 2000px) {
        width: 75%;
    }
    
    @media screen and (min-width: 2500px) {
        width: 67%;
    }
    
    @media screen and (min-width: 3000px) {
        width: 50%;
    }
    
    @media screen and (min-width: 4000px) {
        width: 33%;
    }
    @media screen and (min-width: 6000px) {
        width: 25%;
    }
`;

export { Nav };
