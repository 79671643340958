import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import APIService from 'api/apiService';
import APIV2Service from 'api/apiV2Service';
import { API } from 'api';

// Libraries
import { Link } from 'react-router-dom';
import { TailSpin } from 'react-loader-spinner';
import Skeleton from 'react-loading-skeleton';
import { debounce } from 'lodash';
import { useGeolocated } from 'react-geolocated';
import { getLocationByCoords } from 'utility/geocode';
import useScrollPosition from '@react-hook/window-scroll';

// Styling
import { Nav } from './style';
import 'react-loading-skeleton/dist/skeleton.css';

// Assets
import LiquimolyLogo from 'assets/img/nav/liquimoly_logo.png';
import LiquimolyLogoMobile from 'assets/img/nav/liquimoly_logo_mobile.png';
import SearchIcon from 'assets/img/nav/search_icon.svg';
import SearchIconRed from 'assets/img/nav/search_icon_red.svg';
import GlobeIcon from 'assets/img/nav/globe_icon.svg';
import HomeIcon from 'assets/img/nav/home_icon.svg';
import LocationIcon from 'assets/img/nav/location_icon.svg';
import CloseIcon from 'assets/img/nav/close_icon.svg';
import BackIcon from 'assets/img/nav/back_icon.svg';
import ProductPlaceholder from 'assets/img/placeholder/searchbar_product.png';

interface ProductType {
    id: string;
    image: string;
    name: string;
    descriptions: string;

}

interface WorkshopType {
    name_id: string,
    id: string;
    name: string;
    address: string;
}

const NavBar = (): JSX.Element => {
    const { coords, isGeolocationEnabled } = useGeolocated({
        positionOptions: {
            enableHighAccuracy: false,
        },
        userDecisionTimeout: 5000,
    });

    const addressRef = useRef(null);
    const addressElemRef = useRef<HTMLInputElement>(null);
    const productRef = useRef(null);
    const workshopRef = useRef(null);
    const workshopElemRef = useRef<HTMLInputElement>(null);
    const history = useHistory();
    const [workshopTypes, setWorkshopTypes] = useState<any[]>([]);
    const [addresses, setAddresses] = useState<any[]>([]);
    const [products, setProducts] = useState<any[]>([]);
    const [workshops, setWorkshops] = useState<any[]>([]);
    const [productSearchInput, setProductSearchInput] = useState('');
    const [productCurrentPage, setProductCurrentPage] = useState(1);
    const [productLastPage, setProductLastPage] = useState(999);
    const [addressInput, setAddressInput] = useState('');
    const [addressCurrentPage, setAddressCurrentPage] = useState(1);
    const [addressLastPage, setAddressLastPage] = useState(999);
    const [workshopInput, setWorkshopInput] = useState('');
    const [workshopCurrentPage, setWorkshopCurrentPage] = useState(1);
    const [workshopLastPage, setWorkshopLastPage] = useState(999);
    const clickRef = useRef<HTMLDivElement>(null);
    const [oriHeight, setOriHeight] = useState('120px');
    const [newHeight, setNewHeight] = useState('131px');
    const { pathname } = useLocation();
    const [showMap, setShowMap] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [activeWorkshopType, setActiveWorkshopType] = useState(1);
    const [selected, setSelected] = useState(0);

    const [isLoading, setIsLoading] = useState(true);
    const [addressLoading, setAddressLoading] = useState(false);
    const [productLoading, setProductLoading] = useState(false);
    const [workshopLoading, setWorkshopLoading] = useState(false);
    const [loadingAddresses, setLoadingAddresses] = useState(false);
    const [loadingWorkshops, setLoadingWorkshops] = useState(false);
    const [loadingProducts, setLoadingProducts] = useState(false);

    const [reqHeaders, setReqHeaders] = useState({});

    // Handle scroll for nav bar appearance
    const [scrollToHideValue, setScrollToHideValue] = useState(90);
    const scrollY = useScrollPosition();
    const [scrollingNavClassname, setScrollingNavClassname] = useState('scrolling-up');
    const [lastScroll, setLastScroll] = useState(0);

    const handleScroll = () => {
        setLastScroll(scrollY);

        // When reach top of page, remove classname
        if (scrollY <= 0) {
            setScrollingNavClassname('');
            return;
        }

        if (scrollY >= scrollToHideValue && scrollY > lastScroll && scrollingNavClassname !== 'scrolling-down') {
            // scrolling down
            setScrollingNavClassname('scrolling-down');
        } else if (scrollY < lastScroll && scrollingNavClassname === 'scrolling-down') {
            // scrolling up
            setScrollingNavClassname('scrolling-up');
        }
    };

    useEffect(() => {
        handleScroll();
    }, [scrollY]);

    // When change path, reset scrolling state
    useEffect(() => {
        setScrollingNavClassname('');
    }, [pathname]);

    const handleScrollToHideValue = () => {
        if (window.innerWidth >= 1200) {
            setScrollToHideValue(170);
        } else if (window.innerWidth >= 768 && window.innerWidth <= 1200) {
            setScrollToHideValue(200);
        } else {
            setScrollToHideValue(108);
        }
    };

    // Handle addresses, products and workshops listing
    const getAddresses = useCallback(
        debounce((value: string) => {
            // ToDo: need check for adjustment after they provided API
            const params: any = { address: value };

            if (workshopTypes.length > 0 && activeWorkshopType) {
                params['workshop_type_id'] = activeWorkshopType;
            }

            APIV2Service.get(API.workshop.getWorkshops, params, reqHeaders)
                .then((res) => {
                    setAddressCurrentPage(res.data.data.meta.current_page);
                    setAddressLastPage(res.data.data.meta.last_page);
                    setAddresses(res.data.data.data);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setAddressLoading(false);
                });
        }, 1000),
        [activeWorkshopType],
    );

    const getProducts = useCallback(
        debounce((value: any) => {
            APIService.get(API.general.getProducts, { name: value })
                .then((res) => {
                    setProductCurrentPage(res.data.data.meta.current_page);
                    setProductLastPage(res.data.data.meta.last_page);
                    setProducts(res.data.data.data);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setProductLoading(false);
                });
        }, 1000),
        [],
    );

    const getWorkshops = useCallback(
        debounce((value: string) => {
            const params: any = { name: value };

            if (workshopTypes.length > 0 && activeWorkshopType) {
                params['workshop_type_id'] = activeWorkshopType;
            }

            APIV2Service.get(API.workshop.getWorkshops, params, reqHeaders)
                .then((res) => {
                    setWorkshopCurrentPage(res.data.data.meta.current_page);
                    setWorkshopLastPage(res.data.data.meta.last_page);
                    setWorkshops(res.data.data.data);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setWorkshopLoading(false);
                });
        }, 1000),
        [activeWorkshopType],
    );

    // Handle for get workshops call when switching types
    const handleSwitch = () => {
        if (selected === 1 && addressElemRef && addressElemRef.current) {
            getAddresses(addressElemRef.current.value);
        }

        if (selected === 3 && workshopElemRef && workshopElemRef.current) {
            getWorkshops(workshopElemRef.current.value);
        }
    };

    useEffect(() => {
        handleSwitch();
    }, [activeWorkshopType]);

    // On first load, check if geolocation enabled to get workshops data
    useEffect(() => {
        const cachedLocation = sessionStorage.getItem('location');
        if (cachedLocation) {
            setReqHeaders(JSON.parse(cachedLocation));
            return;
        }

        if (isGeolocationEnabled && coords && !cachedLocation) {
            const fetchLocation = async () => {
                const location = await getLocationByCoords(coords.latitude, coords.longitude);

                const headers: any = {};
                if (location) {
                    headers['country'] = location.country;
                    headers['state'] = location.state;
                    headers['province'] = location.city;
                }

                setReqHeaders(headers);
            };

            fetchLocation();
        }
    }, [isGeolocationEnabled, coords]);

    useEffect(() => {
        if (window.location.pathname === '/offline') {
            return
        }
        APIService.get(API.general.getWorkshopTypes)
            .then((res) => {
                setWorkshopTypes(res.data.data.data);

                const firstId = res.data.data.data[0].id;
                const defaultItem = res.data.data.data.find(({ is_default }: any) => is_default == true);

                if (defaultItem) {
                    setActiveWorkshopType(defaultItem.id);
                } else {
                    setActiveWorkshopType(firstId);
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const handleHeight = () => {
        if (window.innerWidth >= 1200) {
            setOriHeight('120px');
            setNewHeight('140px');
        } else if (window.innerWidth >= 768 && window.innerWidth <= 1200) {
            setOriHeight('150px');
            setNewHeight('200px');
        } else {
            setOriHeight('108px');
            setNewHeight('108px');
        }
    };

    useEffect(() => {
        handleHeight();
        window.addEventListener('resize', handleHeight);
        handleScrollToHideValue();
        window.addEventListener('resize', handleScrollToHideValue);

        return () => {
            window.removeEventListener('resize', handleHeight);
            window.removeEventListener('resize', handleScrollToHideValue);
        };
    }, []);

    const handleOutsideClick = (e: any) => {
        if (clickRef && clickRef.current) {
            if (!clickRef.current.contains(e.target)) {
                try {
                    closeSearch();
                } catch (e) {
                    console.log(e);
                }
            }
        }
    };

    const openSearch = () => {
        document.addEventListener('click', handleOutsideClick, true);
        setShowSearch(true);
    };

    const closeSearch = () => {
        document.removeEventListener('click', handleOutsideClick, true);
        setShowSearch(false);
        deselect();
    };

    const deselect = () => {
        setSelected(0);
    };

    const handleChange = (type: string, event: any) => {
        if (type == 'address') {
            const { value } = event.target;
            setAddressInput(value);

            if (value.length >= 2) {
                getAddresses(value);
                setAddressLoading(true);
            } else {
                setAddresses([]);
                setAddressLoading(false);
            }
        }

        if (type == 'product') {
            const { value } = event.target;
            setProductSearchInput(value);

            if (value.length >= 2) {
                getProducts(value);
                setProductLoading(true);
            } else {
                setProducts([]);
                setProductLoading(false);
            }
        }

        if (type == 'workshop') {
            const { value } = event.target;
            setWorkshopInput(value);

            if (value.length >= 2) {
                getWorkshops(value);
                setWorkshopLoading(true);
            } else {
                setWorkshops([]);
                setWorkshopLoading(false);
            }
        }
    };

    const handleAddressScroll = (event: any) => {
        const currentAddresses = [...addresses];
        const urlParam: any = {};
        const { scrollHeight, scrollTop, clientHeight } = event.target;
        const scroll = (scrollTop / (scrollHeight - clientHeight)) * 100;

        if (addressCurrentPage === addressLastPage) {
            return;
        }

        if (scroll >= 97 && !loadingAddresses) {
            setLoadingAddresses(true);

            if (addressInput) {
                urlParam['address'] = addressInput;
            }

            urlParam['page'] = addressCurrentPage + 1;

            APIV2Service.get(API.workshop.getWorkshops, urlParam)
                .then((res) => {
                    res.data.data.data.map((item: any) => {
                        currentAddresses.push(item);
                    });

                    setAddressCurrentPage(res.data.data.meta.current_page);
                    setAddresses(currentAddresses);

                    if (addressRef && addressRef.current) {
                        event.target.scrollTop = scrollTop;
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setTimeout(() => {
                        setLoadingAddresses(false);
                    }, 1000);
                });
        }
    };

    const handleWorkshopScroll = (event: any) => {
        const currentWorkshops = [...workshops];
        const urlParam: any = {};
        const { scrollHeight, scrollTop, clientHeight } = event.target;
        const scroll = (scrollTop / (scrollHeight - clientHeight)) * 100;

        if (workshopCurrentPage === workshopLastPage) {
            return;
        }

        if (scroll >= 97 && !loadingWorkshops) {
            setLoadingWorkshops(true);

            if (workshopInput) {
                urlParam['name'] = workshopInput;
            }

            urlParam['page'] = workshopCurrentPage + 1;

            APIService.get(API.workshop.getWorkshops, urlParam)
                .then((res) => {
                    res.data.data.data.map((item: any) => {
                        currentWorkshops.push(item);
                    });

                    setWorkshopCurrentPage(res.data.data.meta.current_page);
                    setWorkshops(currentWorkshops);

                    if (workshopRef && workshopRef.current) {
                        event.target.scrollTop = scrollTop;
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setTimeout(() => {
                        setLoadingWorkshops(false);
                    }, 1000);
                });
        }
    };

    const handleProductScroll = (event: any) => {
        const currentProducts = [...products];
        const urlParam: any = {};
        const { scrollHeight, scrollTop, clientHeight } = event.target;
        const scroll = (scrollTop / (scrollHeight - clientHeight)) * 100;

        if (productCurrentPage === productLastPage) {
            return;
        }

        if (scroll >= 97 && !loadingProducts) {
            setLoadingProducts(true);

            if (productSearchInput) {
                urlParam['name'] = productSearchInput;
            }

            urlParam['page'] = productCurrentPage + 1;

            APIService.get(API.general.getProducts, urlParam)
                .then((res) => {
                    res.data.data.data.map((item: any) => {
                        currentProducts.push(item);
                    });

                    setProductCurrentPage(res.data.data.meta.current_page);
                    setProducts(currentProducts);

                    if (productRef && productRef.current) {
                        event.target.scrollTop = scrollTop;
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setTimeout(() => {
                        setLoadingProducts(false);
                    }, 1000);
                });
        }
    };

    const clearInput = (type: string) => {
        if (type == 'address') {
            setAddressInput('');
            setAddresses([]);
        }

        if (type == 'product') {
            setProductSearchInput('');
            setProducts([]);
        }

        if (type == 'workshop') {
            setWorkshopInput('');
            setWorkshops([]);
        }
    };

    const Address = ({ name_id, name, address }: WorkshopType): JSX.Element => {
        return (
            <div className="workshop-item row">
                <div className="location-container col-3 ps-2 ps-md-0">
                    <div
                        className="location-image"
                        onClick={() => {
                            setAddressInput(address), deselect();
                        }}
                    >
                        <img src={LocationIcon} alt="location-image" />
                    </div>
                </div>
                <div className="workshop-container col-9" onClick={() => closeSearch()}>
                    <Link to={'/workshop/' + name_id} style={{ width: '100%', textDecoration: 'none' }}>
                        <div className="workshop-name">{name}</div>
                        <div className="workshop-address">{address}</div>
                    </Link>
                </div>
            </div>
        );
    };

    const Product = ({ image, name, descriptions }: ProductType): JSX.Element => {
        const [imageSrc, setImageSrc] = useState(image ? image : ProductPlaceholder);

        return (
            <div
                className="product-item row"
                onClick={() => {
                    setProductSearchInput(name), deselect();
                }}
            >
                <div className="col-4 ps-1 ps-md-auto">
                    <div className="image-container">
                        <img src={imageSrc} alt="product-image" onError={() => setImageSrc(ProductPlaceholder)} />
                    </div>
                </div>
                <div className="col-8">
                    <div className="details-container">
                        <div className="detail-name">{name}</div>
                        <div className="detail-descriptions">
                            <div className="detail-text">{descriptions}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const Workshop = ({ name_id, name, address }: WorkshopType): JSX.Element => {
        return (
            <div className="workshop-item row">
                <div className="location-container col-3 ps-2 ps-md-0">
                    <div
                        className="location-image"
                        onClick={() => {
                            setWorkshopInput(name), deselect();
                        }}
                    >
                        <img src={LocationIcon} alt="location-image" />
                    </div>
                </div>
                <div className="workshop-container col-9" onClick={() => closeSearch()}>
                    <Link to={'/workshop/' + name_id} style={{ width: '100%', textDecoration: 'none' }}>
                        <div className="workshop-name">{name}</div>
                        <div className="workshop-address">{address}</div>
                    </Link>
                </div>
            </div>
        );
    };

    const handleSearch = () => {
        const params = new URLSearchParams();

        if (workshopTypes.length > 0 && activeWorkshopType) {
            params.append('type', activeWorkshopType.toString());
        }

        if (addressInput) {
            params.append('address', addressInput);
        }

        if (productSearchInput) {
            params.append('product_name', productSearchInput);
        }

        if (workshopInput) {
            params.append('name', workshopInput);
        }

        if (pathname != '/workshop') {
            if (params.toString()) {
                history.push('/?' + params.toString());
            } else {
                history.push('/');
            }
        } else {
            history.replace('/workshop?' + params.toString());
        }

        closeSearch();
    };

    return (
        <>
            <Nav
                className={`${scrollingNavClassname}`}
                style={{ height: showSearch ? newHeight : oriHeight }}
                ref={clickRef}
            >
                <div className="navbar-section">
                    <div className="logo-container">
                        <Link to="/" onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}>
                            <img src={LiquimolyLogo} className="logo-image" />
                        </Link>
                    </div>
                    <div className="searchbar-container">
                        <div
                            className={'searchbar ' + (showSearch && !showMap ? 'd-flex d-md-none' : 'd-flex')}
                            onClick={() => openSearch()}
                        >
                            <img src={SearchIconRed} alt="search-image" className="mobile-search d-block d-md-none" />
                            <span>
                                {window.innerWidth <= 768
                                    ? 'Search your Service Partner'
                                    : 'Search for your Service Partner'}
                            </span>
                            <button className="search-image d-none d-md-flex">
                                <img src={SearchIcon} alt="search-image" />
                            </button>
                        </div>
                        <div className={'vehicle-menu ' + (showSearch && !showMap ? 'd-none d-md-flex' : 'd-none')}>
                            {isLoading ? (
                                <div className="skeleton-container">
                                    <Skeleton width={100} height={22} />
                                    <Skeleton width={34} height={22} />
                                    <Skeleton width={66} height={22} />
                                </div>
                            ) : (
                                workshopTypes.length > 0 &&
                                workshopTypes.map(({ id, name }, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={
                                                'vehicle-container ' + (activeWorkshopType == id ? 'active' : '')
                                            }
                                            onClick={() => setActiveWorkshopType(id)}
                                        >
                                            {name}
                                        </div>
                                    );
                                })
                            )}
                        </div>
                    </div>
                    <div className="toggle-container">
                        <Link
                            to={pathname == '/workshop' ? '/' : '/workshop'}
                            onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
                        >
                            <div className={'toggle-button ' + (pathname == '/workshop' ? 'isMap' : '')}>
                                <span>
                                    {pathname == '/workshop' ? 'Home' : window.innerWidth <= 768 ? 'Map' : 'Show Map'}
                                </span>
                                <div className="globe-image">
                                    <img src={pathname == '/workshop' ? HomeIcon : GlobeIcon} alt="globe-image" />
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="searchmenu-container d-none d-md-block">
                        <div className={'searchmenu ' + (showSearch && !showMap ? 'd-flex' : 'd-none')}>
                            <div className="option-bar">
                                <div
                                    className={'first-item option-item ' + (selected == 1 ? 'active' : '')}
                                    onClick={() => setSelected(1)}
                                >
                                    <div className="option-title">Address</div>
                                    <input
                                        ref={addressElemRef}
                                        className="option-textfield"
                                        placeholder="Search by address"
                                        value={addressInput}
                                        onChange={(e) => handleChange('address', e)}
                                    ></input>
                                    <div
                                        className={'close-button ' + (selected == 1 ? 'd-flex' : 'd-none')}
                                        onClick={() => clearInput('address')}
                                    >
                                        <img src={CloseIcon} alt="close-image" />
                                    </div>
                                </div>
                                <div className="vertical-border"></div>
                                <div
                                    className={'option-item ' + (selected == 2 ? 'active' : '')}
                                    onClick={() => setSelected(2)}
                                >
                                    <div className="option-title">Product</div>
                                    <input
                                        className="option-textfield"
                                        placeholder="Search by product"
                                        value={productSearchInput}
                                        onChange={(e) => handleChange('product', e)}
                                    ></input>
                                    <div
                                        className={'close-button ' + (selected == 2 ? 'd-flex' : 'd-none')}
                                        onClick={() => clearInput('product')}
                                    >
                                        <img src={CloseIcon} alt="close-image" />
                                    </div>
                                </div>
                                <div className="vertical-border"></div>
                                <div
                                    className={'option-item ' + (selected == 3 ? 'active' : '')}
                                    onClick={() => setSelected(3)}
                                >
                                    <div className="option-title">Workshop</div>
                                    <input
                                        ref={workshopElemRef}
                                        className="option-textfield"
                                        placeholder="Search by name"
                                        value={workshopInput}
                                        onChange={(e) => handleChange('workshop', e)}
                                    ></input>
                                    <div
                                        className={'close-button ' + (selected == 3 ? 'd-flex' : 'd-none')}
                                        onClick={() => clearInput('workshop')}
                                    >
                                        <img src={CloseIcon} alt="close-image" />
                                    </div>
                                </div>
                            </div>
                            <button
                                className={'search-button ' + (selected ? 'selected' : '')}
                                onClick={handleSearch}
                            >
                                <img src={SearchIcon} alt="search-image" />
                                <div className={'search-text ' + (selected ? 'd-none d-xl-block' : 'd-none')}>
                                    Search
                                </div>
                            </button>
                            {(addressInput.length >= 2 || addressLoading) && (
                                <div className={selected == 1 ? 'address-modal' : 'd-none'}>
                                    {addressLoading ? (
                                        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                                            <TailSpin color="#E40613" height={30} width={30} />
                                        </div>
                                    ) : (
                                        <div className="address-list" onScroll={(e) => handleAddressScroll(e)}>
                                            {addresses.length > 0 ? (
                                                addresses.map(({ name_id, id, name, address }, index) => {
                                                    return (
                                                        <Address
                                                            name_id={name_id}
                                                            key={'searchWorkshop' + index}
                                                            id={id}
                                                            name={name}
                                                            address={address}
                                                        />
                                                    );
                                                })
                                            ) : (
                                                <div className="nofound-text my-2">No workshop found</div>
                                            )}

                                            <div
                                                className={
                                                    'loading-container ' + (loadingAddresses ? 'd-flex' : 'd-none')
                                                }
                                            >
                                                <TailSpin color="#E40613" height={30} width={30} />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                            {(productSearchInput.length >= 2 || productLoading) && (
                                <div className={selected == 2 ? 'product-modal' : 'd-none'}>
                                    {productLoading ? (
                                        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                                            <TailSpin color="#E40613" height={30} width={30} />
                                        </div>
                                    ) : (
                                        <div className="product-list" onScroll={(e) => handleProductScroll(e)}>
                                            {products.length > 0 ? (
                                                products.map(({ id, name, image_url, category_name }, index) => {
                                                    return (
                                                        <Product
                                                            key={index}
                                                            id={id}
                                                            image={image_url}
                                                            name={name}
                                                            descriptions={category_name}
                                                        />
                                                    );
                                                })
                                            ) : (
                                                <div className="nofound-text mx-auto">No product found</div>
                                            )}

                                            <div
                                                className={
                                                    'loading-container ' + (loadingProducts ? 'd-flex' : 'd-none')
                                                }
                                            >
                                                <TailSpin color="#E40613" height={30} width={30} />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                            {(workshopInput.length >= 2 || workshopLoading) && (
                                <div className={selected == 3 ? 'workshop-modal' : 'd-none'}>
                                    {workshopLoading ? (
                                        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                                            <TailSpin color="#E40613" height={30} width={30} />
                                        </div>
                                    ) : (
                                        <div className="workshop-list" onScroll={(e) => handleWorkshopScroll(e)}>
                                            {workshops.length > 0 ? (
                                                workshops.map(({ name_id, id, name, address }, index) => {
                                                    return (
                                                        <Workshop
                                                            name_id={name_id}
                                                            key={'searchWorkshop' + index}
                                                            id={id}
                                                            name={name}
                                                            address={address}
                                                        />
                                                    );
                                                })
                                            ) : (
                                                <div className="nofound-text my-2">No workshop found</div>
                                            )}

                                            <div
                                                className={
                                                    'loading-container ' + (loadingWorkshops ? 'd-flex' : 'd-none')
                                                }
                                            >
                                                <TailSpin color="#E40613" height={30} width={30} />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className={'mobile-searchmenu ' + (showSearch && !showMap ? 'd-block d-md-none' : 'd-none')}>
                        <div className="searchmenu-content">
                            <div className="logo-container">
                                <img src={LiquimolyLogoMobile} className="logo-image" />
                            </div>
                            <div className="d-flex justify-content-between">
                                <div className="vehicle-options">
                                    {isLoading ? (
                                        <div className="skeleton-container">
                                            <Skeleton width={80} height={17} />
                                            <Skeleton width={24} height={17} />
                                            <Skeleton width={52} height={17} />
                                        </div>
                                    ) : (
                                        workshopTypes.length > 0 &&
                                        workshopTypes.map(({ id, name }, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className={
                                                        'vehicle-container ' +
                                                        (activeWorkshopType == id ? 'active' : '')
                                                    }
                                                    onClick={() => setActiveWorkshopType(id)}
                                                >
                                                    {name}
                                                </div>
                                            );
                                        })
                                    )}
                                </div>
                                <div className="toggle-container ">
                                    <Link
                                        to={pathname == '/workshop' ? '/' : '/workshop'}
                                        onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
                                    >
                                        <div
                                            className={'toggle-button ' + (pathname == '/workshop' ? 'isMap' : '')}
                                            onClick={() => setShowMap(pathname == '/workshop' ? false : true)}
                                        >
                                            <span>
                                                {pathname == '/workshop'
                                                    ? 'Home'
                                                    : window.innerWidth <= 768
                                                        ? 'Map'
                                                        : 'Show Map'}
                                            </span>
                                            <div className="globe-image">
                                                <img
                                                    src={pathname == '/workshop' ? HomeIcon : GlobeIcon}
                                                    alt="globe-image"
                                                />
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="back-button" onClick={() => closeSearch()}>
                                    <img src={BackIcon} className="back-image" />
                                </div>
                                <div className="address-section w-100">
                                    <div className="section-title">Address</div>
                                    <div className="d-flex justify-content-between w-100">
                                        <input
                                            ref={addressElemRef}
                                            className="input-field"
                                            placeholder="Search by address"
                                            onClick={() => setSelected(1)}
                                            value={addressInput}
                                            onChange={(e) => handleChange('address', e)}
                                        ></input>
                                        <div
                                            className={'close-button ' + (selected == 1 ? 'd-flex d-md-none' : 'd-none')}
                                            onClick={() => {
                                                deselect(), clearInput('address');
                                            }}
                                        >
                                            <img src={CloseIcon} alt="close-image" />
                                        </div>
                                    </div>
                                    {(addressInput.length >= 2 || addressLoading) && (
                                        <div
                                            className={'address-list ' + (selected == 1 ? 'd-block d-md-none' : 'd-none')}
                                            onScroll={(e) => handleAddressScroll(e)}
                                            ref={addressRef}
                                        >
                                            {addressLoading ? (
                                                <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                                                    <TailSpin color="#E40613" height={30} width={30} />
                                                </div>
                                            ) : addresses.length > 0 ? (
                                                addresses.map(({ name_id, id, name, address }, index) => {
                                                    return (
                                                        <Address
                                                            name_id={name_id}
                                                            key={'seacrhAddressMobile' + index}
                                                            id={id}
                                                            name={name}
                                                            address={address}
                                                        />
                                                    );
                                                })
                                            ) : (
                                                <div className="error-container">
                                                    <div className="nofound-text text-center">No workshop found</div>
                                                </div>
                                            )}

                                            <div
                                                className={'loading-container ' + (loadingAddresses ? 'd-flex' : 'd-none')}
                                            >
                                                <TailSpin color="#E40613" height={30} width={30} />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="product-section">
                                <div className="section-title">Product</div>
                                <div className="d-flex justify-content-between w-100">
                                    <input
                                        className="input-field"
                                        placeholder="Search by product"
                                        onClick={() => setSelected(2)}
                                        value={productSearchInput}
                                        onChange={(e) => handleChange('product', e)}
                                    ></input>
                                    <div
                                        className={'close-button ' + (selected == 2 ? 'd-flex d-md-none' : 'd-none')}
                                        onClick={() => {
                                            deselect(), clearInput('product');
                                        }}
                                    >
                                        <img src={CloseIcon} alt="close-image" />
                                    </div>
                                </div>
                                {(productSearchInput.length >= 2 || productLoading) && (
                                    <div
                                        className={'product-list ' + (selected == 2 ? 'd-block d-md-none' : 'd-none')}
                                        onScroll={(e) => handleProductScroll(e)}
                                        ref={productRef}
                                    >
                                        {productLoading ? (
                                            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                                                <TailSpin color="#E40613" height={30} width={30} />
                                            </div>
                                        ) : products.length > 0 ? (
                                            products.map(({ id, name, image_url, category_name }, index) => {
                                                return (
                                                    <Product
                                                        key={index}
                                                        id={id}
                                                        image={image_url}
                                                        name={name}
                                                        descriptions={category_name}
                                                    />
                                                );
                                            })
                                        ) : (
                                            <div className="error-container">
                                                <div className="nofound-text text-center">No product found</div>
                                            </div>
                                        )}
                                        <div className={'loading-container ' + (loadingProducts ? 'd-flex' : 'd-none')}>
                                            <TailSpin color="#E40613" height={30} width={30} />
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="workshop-section">
                                <div className="section-title">Workshop</div>
                                <div className="d-flex justify-content-between w-100">
                                    <input
                                        ref={workshopElemRef}
                                        className="input-field"
                                        placeholder="Search by name"
                                        onClick={() => setSelected(3)}
                                        value={workshopInput}
                                        onChange={(e) => handleChange('workshop', e)}
                                    ></input>
                                    <div
                                        className={'close-button ' + (selected == 3 ? 'd-flex d-md-none' : 'd-none')}
                                        onClick={() => {
                                            deselect(), clearInput('workshop');
                                        }}
                                    >
                                        <img src={CloseIcon} alt="close-image" />
                                    </div>
                                </div>
                                {(workshopInput.length >= 2 || workshopLoading) && (
                                    <div
                                        className={'workshop-list ' + (selected == 3 ? 'd-block d-md-none' : 'd-none')}
                                        onScroll={(e) => handleWorkshopScroll(e)}
                                        ref={workshopRef}
                                    >
                                        {workshopLoading ? (
                                            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                                                <TailSpin color="#E40613" height={30} width={30} />
                                            </div>
                                        ) : workshops.length > 0 ? (
                                            workshops.map(({ name_id, id, name, address }, index) => {
                                                return (
                                                    <Workshop
                                                        name_id={name_id}
                                                        key={'seacrhWorkshopMobile' + index}
                                                        id={id}
                                                        name={name}
                                                        address={address}
                                                    />
                                                );
                                            })
                                        ) : (
                                            <div className="error-container">
                                                <div className="nofound-text text-center">No workshop found</div>
                                            </div>
                                        )}

                                        <div
                                            className={'loading-container ' + (loadingWorkshops ? 'd-flex' : 'd-none')}
                                        >
                                            <TailSpin color="#E40613" height={30} width={30} />
                                        </div>
                                    </div>
                                )}
                            </div>
                            <button
                                className="search-button"
                                onClick={handleSearch}
                            >
                                <img src={SearchIcon} alt="search-image" />
                                <div className="search-text">Search</div>
                            </button>
                        </div>
                    </div>
                </div>
            </Nav>
        </>
    );
};

export default NavBar;
